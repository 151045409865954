import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="relative select-none">
      {/* Desktop Navigation */}
      <div className="hidden lg:flex justify-center relative">
        <div className="flex gap-8 text-4xl font-moreSugarThin mt-10">
          <Link to="/home" className="hover-animation">Home</Link>
          <Link to="/raffles" className="hover-animation">Raffles</Link>
          <Link to="/album" className="hover-animation">Album</Link>
          <Link to="/about-us" className="hover-animation">About Us</Link>
          <Link to="/contact" className="hover-animation">Contact</Link>
        </div>
        <img src={`${process.env.PUBLIC_URL}/instagram.png`} alt="iglogo" className="absolute right-4 top-4 w-10 h-10" />
      </div>

      {/* Mobile Navigation - For all mobile devices */}
      <div className="lg:hidden">
        <button
          className={`fixed top-2 z-50 transition-all duration-300 ease-in-out ${
            isOpen ? 'left-52' : 'left-2'
          }`}
          onClick={toggleMenu}
          aria-label={isOpen ? "Close menu" : "Open menu"}
        >
          <div className="relative w-6 h-6">
            <Menu 
              size={24} 
              className={`absolute transition-opacity duration-300 ${
                isOpen ? 'opacity-0' : 'opacity-100'
              }`}
            />
            <X 
              size={24} 
              className={`absolute transition-opacity duration-300 ${
                isOpen ? 'opacity-100' : 'opacity-0'
              }`}
            />
          </div>
        </button>

        <div
          className={`${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          } fixed left-0 top-0 h-full w-60 bg-[#e7c6c3] shadow-lg z-40 transition-transform duration-300 ease-in-out flex flex-col justify-between`}
        >
          <div className="flex flex-col gap-3 text-xl sm:text-2xl font-moreSugarThin mt-10 p-4">
            <Link to="/" className="hover-animation" onClick={() => setIsOpen(false)}>Home</Link>
            <Link to="/raffles" className="hover-animation" onClick={() => setIsOpen(false)}>Raffles</Link>
            <Link to="/album" className="hover-animation" onClick={() => setIsOpen(false)}>Album</Link>
            <Link to="/about-us" className="hover-animation" onClick={() => setIsOpen(false)}>About Us</Link>
            <Link to="/contact" className="hover-animation" onClick={() => setIsOpen(false)}>Contact</Link>
          </div>
          <div className="p-4 mb-4">
            <img src={`${process.env.PUBLIC_URL}/instagram.png`} alt="iglogo" className="w-8 h-8" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;