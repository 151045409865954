import React from 'react';

const Home = () => {
  const spanRefs = React.useRef([]);

  React.useEffect(() => {
    spanRefs.current.forEach((span, idx) => {
      span.addEventListener('click', (e) => {
        e.target.classList.add('active');
      });
      span.addEventListener('animationend', (e) => {
        e.target.classList.remove('active');
      });
      
      // Initial animation
      setTimeout(() => {
        span.classList.add('active');
      }, 450 * (idx + 1));
    });
  }, []);

  return (
    <>
      <div className="relative flex flex-col min-h-90vh overflow-hidden select-none">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-8 sm:gap-4 lg:gap-12 padding-on-landscape">
          {/* Title with animation */}
          <div className="lg:hidden text-center p-1 mt-1 w-max">
            <h2 className="font-moreSugarRegular text-4xl word">
              {'MeowlKeys'.split('').map((letter, index) => (
                <span 
                  key={index} 
                  ref={el => spanRefs.current[index] = el}
                  className="cursor-pointer"
                >
                  {letter}
                </span>
              ))}
            </h2>
          </div>
          {/* Logo */}
          <img 
            src={`${process.env.PUBLIC_URL}/meowlkeysLogo.png`} 
            alt="MeowlKeys Logo" 
            className="w-60 h-60 sm:w-40 sm:h-40 lg:w-144 lg:h-144 object-contain" 
          />
          {/* Intro line */}
          <h1 className="font-moreSugarRegular text-xl lg:text-5xl text-center w-full px-2 sm:px-4 leading-tight">
            Fancy an unique, purrfect Handmade Artisan Keycaps?
          </h1>
        </div>
      </div>

      {/* Copyright section */}
      <div className="absolute bottom-0 left-0 w-full px-2 sm:px-3 py-1 sm:py-2 text-center lg:text-left hide-on-landscape">
        <span className="text-xs text-gray-600">
          Copyright © 2024 MeowlKeys - All Rights Reserved.
        </span>
      </div>
    </>
  );
};

export default Home;